<template>
  <div class="item-features">
    <div class="row text-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon icon="AwardIcon" size="35" />
          <h4 class="mt-2 mb-1">
            Zuverlässig
          </h4>
          <p class="card-text">
            Wir sind ein zuverlässiger Partner für Selfservice Lagereinheiten.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon icon="ClockIcon" size="35" />
          <h4 class="mt-2 mb-1">
            Rund um die Uhr
          </h4>
          <p class="card-text">
            Sie haben 24/7 Zugang zu Ihrer Selfservice Lagereinheit.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon icon="ShieldIcon" size="35" />
          <h4 class="mt-2 mb-1">
            Sicher
          </h4>
          <p class="card-text">
            Unsere Selfservice Lagereinheiten sind videoüberwacht und
            alarmgesichert.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
