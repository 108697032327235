<template>
  <section class="app-ecommerce-details">
    <!-- {{ getStorageType.image[0].url }} -->
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Fehler beim laden des Produkts!</h4>
      <div class="alert-body">
        Kein Lager gefunden. Check
        <b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }">
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="product" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div
              v-if="product.image.length > 0"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                :alt="`${product.name}`"
                fluid
                class="card-img-top"
                :src="`${baseURL}${product.image[0].url}`"
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <!-- <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ product.brand }}
              </b-link>
            </b-card-text> -->

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">{{ product.price }}€</h4>
            </div>

            <!-- Avability -->
            <b-card-text
              >Verfügbarkeit -
              <span class="text-success">Buchbar</span></b-card-text
            >

            <!-- Product Description -->
            <b-card-text v-html="product.description"></b-card-text>

            <hr />

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="
                  $store.getters.cart && $store.getters.cart.includes(product)
                    ? 'success'
                    : 'primary'
                "
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="handleCartActionClick(product)"
              >
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{
                  $store.getters.cart && $store.getters.cart.includes(product)
                    ? 'Buchungsanfrage anzeigen'
                    : 'Buchungsanfrage hinzufügen'
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <e-commerce-product-details-item-features />

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <!-- <e-commerce-product-details-related-products /> -->
    </b-card>
  </section>
</template>

<script>
// import store from '@/store'
// import { ref } from '@vue/composition-api'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import API from '@/service/api'
import { ref } from '@vue/composition-api'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    API,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const product = ref(null)

    // Remote Data
    const fetchProduct = () => {
      // Get product  id from URL
      const { route } = useRouter()
      // const productSlug = route.value.params.slug
      // const productId = productSlug.substring(productSlug.lastIndexOf('-') + 1)

      product.value = store.getters.getStorageTypesById(
        Number(route.value.params.id)
      )
    }

    // UI
    const selectedColor = ref(null)

    fetchProduct()

    return {
      // Fetched Product
      product,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
  data() {
    return {
      baseURL: API().defaults.baseURL,
    }
  },

  // data() {
  //   return {
  //     // Fetched Product
  //     product: null,

  //     // UI
  //     selectedColor: null,
  //     baseURL: API().defaults.baseURL,
  //   }
  // },

  // computed: {
  //   getStorageType() {
  //     return this.$store.getters.getStorageTypesById(
  //       Number(this.$route.params.id)
  //     )
  //   },
  // },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
